/* eslint-disable @typescript-eslint/no-explicit-any */

import React, {
	CSSProperties,
	FC,
	useCallback,
	useEffect,
	useMemo,
	useRef,
} from 'react';

import { DateCalender, Tooltip } from '@storybook';
import { DeviceType, useDeviceType } from 'views';
import { formatDate } from 'utils';
import { DateTime } from 'luxon';
import { getCalenderDirection } from '@storybook/react-date-picker/utils';

import { IMetadata, ITab } from '../store';

interface IProps {
	name: string;
	metadata: IMetadata;
	label: string;
	handleChangeText: (e: string) => void;
	nodeId: string;
	value?: string;
	isSelectedClass?: string;
	node: ITab;
	handleSelectedNode?: React.FocusEventHandler<HTMLInputElement>;
	styles?: CSSProperties;
	isDisabled?: boolean;
	pageWidth: number;
	pageHeight: number;
	formate?: string;
}

export const DateNode: FC<IProps> = ({
	isSelectedClass,
	handleChangeText,
	label,
	metadata,	
	nodeId,
	handleSelectedNode,
	value,
	node,
	styles,
	isDisabled,
	pageWidth,
	pageHeight,
	formate
}) => {
	const { deviceType } = useDeviceType();
	const dateRef = useRef<HTMLInputElement>(null);
	useEffect(() => {
		const dateInput = document.getElementById(
			`${nodeId}-date-input-node`
		) as HTMLElement;
		if (isSelectedClass && dateInput) {
			dateInput.style.border = 'none !important';
			dateInput.style.color = `${metadata.fontColor}`;
			dateInput.style.fontSize = `${metadata.fontSize}`;
			dateInput.style.fontFamily = `${metadata.fontFamily}`;
			dateInput?.focus();
		}
	}, [
		isSelectedClass,
		metadata.fontColor,
		metadata.fontFamily,
		metadata.fontSize,
		nodeId,
	]);

	const formattedDate = useMemo(() => {
		if (value) {
			const newDate = new Date(value);
			const date = formatDate(newDate, 'mm/dd/yyyy');
			const now = DateTime.fromFormat(date, 'MM/dd/yyyy');
			const currentMonth = now?.month; // Month (1-12);
			const currentYear = now?.year; // Year (e.g., 2024);
			const currentDay = now?.day; // Day of the month (1-31);
			const data = {
				year: currentYear ?? '',
				month: currentMonth ?? '',
				day: currentDay ?? '',
			};
			return data;
		}
		return null;
	}, [value]);

	const handleDateChange = useCallback(
		(event: any) => {
			const { value } = event.target;
			if (value) {
				const { day, year, month } = value;
				const date = `${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}/${year}`;				
				handleChangeText(date);
			}
		},
		[handleChangeText]
	);

	const direction = useMemo(
		() => getCalenderDirection(pageWidth, pageHeight, node.position),
		[node, pageHeight, pageWidth]
	);

	return (
		<>
			<div
				className={`signing-node signing-node__date-field date-input ${isSelectedClass}`}
				ref={dateRef}
				onFocus={handleSelectedNode}
			>
				<DateCalender
					label=""
					id={`${nodeId}-date-input-node`}					
					onChange={handleDateChange}
					value={formattedDate}
					inputStyle={{ minWidth: 80, ...styles }}
					isDisabled={isDisabled}
					direction={direction}
					formate={formate}
					isInputEditable={false}
				/>
			</div>
			
			{!metadata.readOnly && deviceType !== DeviceType.Mobile && (
				<Tooltip
					id={`${nodeId}-date-input-node`}
					variant="info"
					content={`${
						node.metadata.required === false
							? 'Optional field'
							: 'Required field'
					} ${label}`}
					className="pdf-signer-container__tooltip"
				/>
			)}
		</>
	);
};
