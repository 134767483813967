import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useNetwork, useNotification } from 'hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { v4 } from 'uuid';
import {
	APP_ROUTES,
	useSetConfigDoc,
	UploadedEnvelopeDocsState,
	templateTypeState,
	prepareTypeState,
	SenderDetailsState,
} from 'views';
import { API_URL, ENVELOPE_PURPOSE, MESSAGE } from 'constant';
import { GetQueryParams } from 'utils';
import {
	isShowConfigDocScreen,
	webComponentEnvelopeIdState,
} from 'states/web-component-state';
import { EnvState, EnvelopePurposeState, IPurpose } from 'states';

import {
	ACTION_DROP_DOWN_OPTIONS,
	IsEnableFacialAuth,
	IRecipientUser,
	IsAddRecipientModalOpen,
	RecipientErrorsState,
	RecipientLocalState,
	RecipientsState,
	SelectedRecipientState,
	getColor,
	isSigningOrderEnabledState,
	newRecipient,
	removeColor,
	IsOriginalCopy,
	IsEnableENotaryToggle,
	INameSuggestions,
	NameSuggestionLoadingState,
	IsEnableMerchantFlow,
} from '.';

export const useValidateFields = () => {
	const localRecipients = useRecoilValue(RecipientLocalState);
	const setErrors = useSetRecoilState(RecipientErrorsState);
	const validateEmail = useCallback((email: string) => {
		return !email.trim().match(/^[\w+.~-]+@([\w-]+\.)+[\w-]{2,13}$/);
	}, []);
	const templateType = useRecoilValue(templateTypeState);
	const prepareType = useRecoilValue(prepareTypeState);
	const templateId = GetQueryParams('templateId');

	const validateName = useCallback((name: string) => {
		const pattern = /^[A-Za-z\s]{3,}$/;
		return !pattern.test(name.trim());
	}, []);
	const validatePrivateMessage = useCallback(
		(message: string) => !message.trim().match(/^(?!\s*$)[\s\S]*$/),
		[]
	);
	const validateFields = useCallback((AlllocalRecipient: IRecipientUser[]) => {
		const errorList: { [key: string]: string[] } = {};

		(AlllocalRecipient || localRecipients).forEach((item: IRecipientUser) => {
			const errorFields = [];
			if (
				(templateType === 'overlay' && prepareType === 'overlay') ||
				templateId
			) {
				if (!item.title || item.title.trim()?.length < 3) {
					errorFields.push('title');
				}
			} else {
				if (!item?.fullName || validateName(item.fullName)) {
					errorFields.push('fullName');
				}
				if (!item.email || validateEmail(item.email)) {
					errorFields.push('email');
				}
				if (item?.isPrivateMessageEnabled) {
					if (validatePrivateMessage(item?.message || '')) {
						errorFields.push('message');
					}
				}
			}
			if (errorFields.length > 0) {
				const id = item?.id ?? item?._id;
				if (id) {
					errorList[id] = errorFields;
				}
			}
		});
		setErrors(errorList);
		if (Object.keys(errorList).length) {
			return false;
		}
		return true;
	}, [
		localRecipients,
		prepareType,
		setErrors,
		templateId,
		templateType,
		validateEmail,
		validateName,
		validatePrivateMessage,
	]);

	return validateFields;
};

export const useFocusElement = () => {
	const focusActiveElement = () => {
		setTimeout(() => {
			const activeElement = document.activeElement;
			if (activeElement) {
				activeElement.scrollIntoView({ behavior: 'smooth' });
			}
		}, 500);
	};

	/**
	 * focus the element that matches the query selector
	 * @param selector valid css selector
	 */
	const focusElement = (selector: string) => {
		setTimeout(() => {
			const element = document.querySelector(selector);
			if (element) {
				(element as HTMLElement).focus({ preventScroll: true });
				element.scrollIntoView({ behavior: 'smooth' });
			}
		});
	};

	return { focusActiveElement, focusElement };
};

export const useRecipient = () => {
	const envelopeData = useRecoilValue(UploadedEnvelopeDocsState);
	const { data: envelopDocs } = envelopeData;
	const [localRecipients, setLocalRecipients] =
		useRecoilState(RecipientLocalState);
	const setIsOpen = useSetRecoilState(IsAddRecipientModalOpen);
	const setRecipients = useSetRecoilState(RecipientsState);
	const { errorNotification } = useNotification();
	const { EMAIL_UNIQUE_MESSAGE, RECIPIENT_SIGN_MESSAGE} =
		MESSAGE;
	const validateFields = useValidateFields();
	const { handleSaveEnvelope } = useSetConfigDoc();
	const signOrder = useRecoilValue(isSigningOrderEnabledState);
	//paras : add facial toggle
	const isEnableFacialAuth = useRecoilValue(IsEnableFacialAuth);
	const navigate = useNavigate();
	const errors = useRecoilValue(RecipientErrorsState);
	const { focusActiveElement } = useFocusElement();
	const { id } = useParams();
	const webComponentEnvelopeId = useRecoilValue(webComponentEnvelopeIdState);
	const setShowDocConfigScreen = useSetRecoilState(isShowConfigDocScreen);
	const setIsNameSuggestionLoading = useSetRecoilState(
		NameSuggestionLoadingState
	);
	const env = useRecoilValue(EnvState);	
	const paramId = id || webComponentEnvelopeId;
	const [nameSuggestion, setNameSuggestion] = useState<INameSuggestions[]>([]);
	const { get } = useNetwork({ updateState: false });

	const templateType = useRecoilValue(templateTypeState);
	const prepareType = useRecoilValue(prepareTypeState);
	const templateId = GetQueryParams('templateId');
	const isOriginalCopyToggle = useRecoilValue(IsOriginalCopy);
	const sender = useRecoilValue(SenderDetailsState);
	const flowPurpose = useRecoilValue(EnvelopePurposeState);
	const isENotaryEnable = useRecoilValue(IsEnableENotaryToggle);
	const isMerchantFlow = useRecoilValue(IsEnableMerchantFlow);
	const AddRecipients = useCallback(() => {
		const existColorCodes: string[] = localRecipients?.map(
			(recipient) => recipient?.color || ''
		);
		const recipient: IRecipientUser = {
			...newRecipient,
			color: getColor(existColorCodes) || "#daa520",
			id: 'temp' + v4(),
			type: "default",
			recipientLocalType: "default",
		};				

		setLocalRecipients((prev) => {
			return [...prev, recipient]
		});
		focusActiveElement();
	}, [focusActiveElement, localRecipients, setLocalRecipients]);

	const setOriginalCopyData = (recipient_id: string) => {
		setLocalRecipients((prevState) =>
			prevState.map((recipientData: IRecipientUser) => ({
				...recipientData,
				originalCopy:
					recipient_id === ''
						? true
						: (recipientData.id ?? recipientData._id) === recipient_id,
			}))
		);
	};

	const DeleteRecipient = useCallback(
		(index: number) => {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			setLocalRecipients((prev: any) => {
				const prevState = [...prev];
				const removedItem = prevState.splice(index, 1);

				if (removedItem.length && removedItem[0]?.color) {
					removeColor(removedItem[0]?.color);
				}
				const originalCopyCount = prevState.filter(
					(item: IRecipientUser) => item.originalCopy === true
				).length;
				if (originalCopyCount === 0) {
					const makeFirstEnable = {
						...prevState[0],
						originalCopy: true,
					};
					return [makeFirstEnable, ...prevState.slice(1, prevState.length)];
				}

				return prevState;
			});
		},
		[setLocalRecipients]
	);

	const resetAddRecipientsModal = useCallback(() => {
		setIsOpen(false);
	}, [setIsOpen]);

	const setRecipientsWithValue = useCallback(
		(
			field: keyof IRecipientUser,
			value: string | boolean | undefined,
			index: number
		) => {
			setLocalRecipients((prev: IRecipientUser[]) => {
				const prevState = JSON.parse(JSON.stringify(prev)) as IRecipientUser[];
				const foundItem = prevState[index] as IRecipientUser;
				// @ts-ignore
				foundItem[field] = value;
				if (field === 'title') {
					foundItem['fullName'] = value as string;
				}
				return prevState;
			});
		},
		[setLocalRecipients]
	);

	const getRecipientsDetails = async (
		recipientFullName: string,
		senderEmail: string
	) => {
		setIsNameSuggestionLoading(true);
		const resp = await get(
			`${API_URL.RECEIPIENT_SUGGESTION}?email=${senderEmail}&name=${recipientFullName}`
		);
		const { apiData } = resp;
		if (apiData?.data) {
			setNameSuggestion(apiData?.data);
		}
		setIsNameSuggestionLoading(false);
	};

	const focusFirstErrorField = useCallback((): void => {
		const firstRecipientIdWithError = Object.keys(errors).find(
			() => true
		) as string;
		const firstErrorField = (
			errors?.[firstRecipientIdWithError] as string[]
		)?.[0] as string;
		document
			.getElementById(`${firstRecipientIdWithError}-${firstErrorField}`)
			?.focus();
	}, [errors]);

	const saveLocalRecipients = useCallback(() => {
		if (validateFields(localRecipients)) {
			let duplicateExist = false;
			const collection: { [key: string]: number } = {};
			for (let index = 0; index < localRecipients.length; index++) {
				const { email } = localRecipients[index] as IRecipientUser;
				if (collection[email]) {
					duplicateExist = true;
					break;
				} else {
					collection[email] = 1;
				}
			}
			if (
				duplicateExist &&
				!(templateType === 'overlay' && prepareType === 'overlay') &&
				!templateId
			) {
				errorNotification(EMAIL_UNIQUE_MESSAGE);
				return false;
			}

			if (
				!localRecipients.some(
					(item) => item.action === ACTION_DROP_DOWN_OPTIONS?.[0]?.value
				)
			) {
				errorNotification(RECIPIENT_SIGN_MESSAGE);
				return false;
			}
			const trimmedRecipients = localRecipients.map((recipient) => ({
				...recipient,
				fullName: recipient.fullName?.trim(),
				email: recipient.email?.trim(),
			}));

			setRecipients(trimmedRecipients);
			return true;
		}
		focusFirstErrorField();

		return false;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [focusFirstErrorField, localRecipients, setRecipients, validateFields]);

	const patchRecipients = useCallback(
		/**
		 * used to patch envelope data with payload
		 * @param shouldNavigate flag that will determine weather we should navigate to other screen after success or not
		 * @param purpose that determines weather it is a for self sign or invite for others
		 * @returns Promise with void
		 */
		async (shouldNavigate = true, purpose?: IPurpose) => {
			const tempRecipient: IRecipientUser[] = JSON.parse(
				JSON.stringify(localRecipients)
			).map(
				({
					message,
					email,
					fullName,
					action,
					role,
					id,
					color,
					originalCopy,
					status,
					recipientLocalType,
				}: IRecipientUser) => {
					const data: IRecipientUser = {
						fullName: fullName?.trim(),
						action,
						role,
						email: email?.trim(),
						colorCode: color,
						originalCopy: isOriginalCopyToggle ? originalCopy : true,
						status,
						type: recipientLocalType,
					};
					if (id && !id.startsWith('temp')) {
						data._id = id;
					}
					if (message) {
						data.message = message.trim();
					}
					return data;
				}
			);
			const selfSignRecipient: IRecipientUser[] = [
				{
					fullName: sender.fullName,
					action: 'Needs to sign',
					role: 'signer',
					email: sender.email,
					colorCode: '#bccbf4',
					type: 'default',
				}
			];
			const documentList = envelopDocs.map(({ id, tabs }) => ({
				document: id,
				tabs: tabs,
			}));
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const payload: { [key: string]: any } = {
				recipients: purpose === 'selfsign' ? selfSignRecipient : tempRecipient,
				documents: documentList,
				signOrder,
				authType: isEnableFacialAuth ? 'facial' : 'webauth',
				purpose: purpose ?? flowPurpose,
			};
			if(templateType === 'editable'){
				delete payload.documents
			}
			if (flowPurpose === ENVELOPE_PURPOSE.MULTISIGNAGREEMENT) {
				payload.reviewSign = isMerchantFlow;
			}
			if (env === 'stage' || env === 'preprod') {
				payload.notary = isENotaryEnable;
			}
			const successful = await handleSaveEnvelope(payload, paramId, false);
			if (successful) {
				if (shouldNavigate) {
					if (id) {
						// navigate to configuration screen when it is not web-component
						navigate(`/${APP_ROUTES.CONFIG_DOC}?envelopeId=${paramId}`);
					} else {
						// switching to configuration screen when the web-component is being used
						setShowDocConfigScreen(true);
					}
				} else {
					resetAddRecipientsModal();
				}
			}
			return successful;
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			localRecipients,
			sender,
			envelopDocs,
			signOrder,
			isEnableFacialAuth,
			flowPurpose,
			env,
			handleSaveEnvelope,
			paramId,
			isOriginalCopyToggle,
			isMerchantFlow,
			isENotaryEnable,
			id,
		]
	);

	return {
		AddRecipients,
		DeleteRecipient,
		resetAddRecipientsModal,
		setRecipientsWithValue,
		saveLocalRecipients,
		patchRecipients,
		setOriginalCopyData,
		getRecipientsDetails,
		nameSuggestion,
	};
};

export const useGetRecipient = () => {
	const recipient = useRecoilValue(SelectedRecipientState);
	const allRecipients = useRecoilValue(RecipientsState);

	const activeRecipientColor = useMemo(() => {
		const { color } = recipient[0] ?? {};
		return color ?? '#eae2be';
	}, [recipient]);

	const activeRecipientId = useMemo(() => {
		const { id } = recipient[0] ?? {};
		return id ?? "";
	}, [recipient]);

	const getRecipientColor = useCallback(
		(id: string) => {
			const foundRecipient = allRecipients.find(
				(recipient) => recipient.id === id
			);
			return foundRecipient?.color;
		},
		[allRecipients]
	);

	return {
		activeRecipientColor,
		activeRecipientId,
		getRecipientColor,
	};
};
/**
 * hook that helps to focus the name of last recipient in the list
 */
export const useScrollToLastRecipient = () => {
	const recipients = useRecoilValue(RecipientLocalState);
	const lastRecipientId = useRef<string | null>();
	const scrollToLast = useCallback(() => {
		const elements = document.querySelectorAll('.add-recipient__form');
		if (elements) {
			const element = elements[elements.length - 1];
			if (element) {
				const firstInputElement = element.getElementsByClassName(
					'input__field'
				) as HTMLCollectionOf<HTMLInputElement>;
				if (firstInputElement) {
					firstInputElement[0]?.focus();
				}
			}
		}
	}, []);
	useEffect(() => {
		if (!lastRecipientId.current) {
			lastRecipientId.current = recipients[recipients.length - 1]?.id;
		} else if (
			recipients[recipients.length - 1]?.id !== lastRecipientId.current
		) {
			lastRecipientId.current = recipients[recipients.length - 1]?.id;
			scrollToLast();
		}
	}, [recipients, scrollToLast]);
};
