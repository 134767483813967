import { ITabObject } from "components";
import { IUploadDocHeader } from "views";

export const UploadDocHeader: IUploadDocHeader[] = [
  {
    label: "Serial No.",
    key: "index",
    key2: "index",
    format: "string",
    width: "10%",
  },
  {
    label: "Document Name",
    key: "documentName",
    key2: "name",
    format: "string",
    width: "10%",
  },
  {
    label: "Total Inputs",
    key: "tabsCount",
    key2: "tabsCount",
    format: "number",
    width: "10%",
  },
  {
    label: "Total Pages",
    key: "pagesCount",
    key2: "pagesCount",
    format: "jsx",
    width: "10%",
  },
  {
    label: "Action",
    key: "actions",
    key2: "delete",
    format: "jsx",
    width: "10%",
  },
];

type ICommon = "FILE_TYPE" | "FILE_SIZE_LIMIT" | "FILES_SUPPORTED" | "NO_RECIPIENTS_ADDED";

export const COMMON_CONST: { [key in Uppercase<ICommon>]: string } = {
  FILE_TYPE: "application/pdf",
  FILE_SIZE_LIMIT: "Maximum upload file size: 25 MB.",
  FILES_SUPPORTED: "Supported file format: Pdf, Doc, Docx",
  NO_RECIPIENTS_ADDED: "No recipients are added", 
};

export const totalTabs: ITabObject[] = [
	{
		title: 'Several People',
		description: 'Invite other people to sign',
		name: 'workflow',
    disabled: false,
	},
	{
		title: 'Self Sign',
		description: 'I am the only signer',
		name: 'selfsign',
    disabled: false,
	},
];
export const multiPeopleTabs: ITabObject[] = [
	{
		title: 'Several People',
		description: 'Invite other people to sign',
		name: 'workflow',
    disabled: false,
	},	
];

export const addRecipientMessage = {
	originalCopy: {
		heading: 'Single original copy',
		description: `Allow only one person from the invitation list to receive the
    original signed document. Meanwhile, all other copies will be
    watermarked as 'COPY' after the document signing is complete.
    This individual can be either the signer or the recipient of
    the document.`,
		warning: null,
	},
	reviewSigning: {
		heading: 'Review prior to signing',
		description: `When enabled, recipients will receive the document to 
    fill out all required fields except for the signature. Once completed, 
    the document can be reviewed and sent back for final signature.`,
		warning: null,
	},
  mandatoryOnboarding: {
    heading: "Mandatory onboarding",
    description: `When enabled, recipient will be required to complete 
    the full onboarding flow before they can sign the document.`,
    warning: null,
  },
	eNotary: {
		heading: 'Notarization',
		description:
			`When enabled, notarization ensure the legal authenticity 
      of a document and all the signers will 
      undergo a KYC process.`,
		warning: {
			message: 'Notarization incurs a fee of $20 per packet.',
			varient: 'warning',
		},
	},
};

type IUploadDocsMessageKeys = "swapped_success" | "swapped_failed" 

export const UPLOAD_DOCS_MESSAGE: {[key in Uppercase<IUploadDocsMessageKeys>] : string} = {
  SWAPPED_FAILED: "Failed to swap document. try again later.",
  SWAPPED_SUCCESS: "Document swapped successfully.",
}