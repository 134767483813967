import { useCallback, useEffect, useState } from 'react';

import { Navigate, Route, Routes } from 'react-router-dom';
import {
	BackToSimplici,
	EditorsDashboard,
	LinkExpired,
	PrepareDashboard,
	ServerError,
	SignDocumentState,
	SignWebComponentDataState,
	SigningDashboard,
	SuccessScreen,
	uiShowHideState,
} from 'views';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
	useClientUser,
	useLocation,
	useNetwork,
	useQueryParams,
	useGetLoationDetails,
	useDocument,
} from 'hooks';
import { SelfSign } from 'views/self-sign';
import {
	EnvState,
	GoogleLocation,
	UserLocationState,
	isShowConfigDocScreen,
	isShowSelfSignDocScreen,
	organizationDetailState,
	webComponentEnvelopeIdState,
} from 'states';
import { GetQueryParams } from 'utils';
import { ESignAgrement } from 'views/e-sign-agreement';

import { APP_ROUTES, ENV_HOST, IEnv } from './store';

const {
	CONFIG_DOC,
	SIGN_DOC,
	ERROR,
	SIGNINSUCCESS,
	CONFIGSUCCESS,
	PREPARE,
	ENVELOPE,
	ENVELOPE_CONFIG_SUCCESS,
	VIEW_SUCCESS,
	REDIRECT_TO_SIMPLICI,
	VIEW_DOC,
	OVERLAY,
	LINK_EXPIRED,
	SELF_SIGN,
	AGREMENT,
} = APP_ROUTES;
interface IAllRoutes {
	url: string;
	env: string;
	submit_esign: (success: boolean, purpose: string) => void;
	hide_user: boolean;
	hide_logo: boolean;
}

export class WebComponentMemory {
	static handleFinish: (success: boolean, purpose: string) => void = () => ({});
	static isWebComponent = false;
	static host: string;
	static envelopeId: string;
}

export const AllRoutes = ({ url, env , submit_esign, hide_logo, hide_user}: IAllRoutes) => {
	const setWebComponentData = useSetRecoilState(SignWebComponentDataState);
	const envelopeId = useRecoilValue(
		webComponentEnvelopeIdState
	);
	const setEnv = useSetRecoilState(EnvState);
	const setUiShowHide = useSetRecoilState(uiShowHideState);
	const showConfigDoc = useRecoilValue(isShowConfigDocScreen);
	const showSelfSignDoc = useRecoilValue(isShowSelfSignDocScreen);
	const organization = useRecoilValue(organizationDetailState);
	const signDocState = useRecoilValue(SignDocumentState);

	const currentRecipientId = GetQueryParams('recipient');
	const locationInfo = useRecoilValue(UserLocationState);
	const googleLocation = useRecoilValue(GoogleLocation);

	const { apiHostExists } = useNetwork();
	const { getReadOnlyParams, getEnvelopeIdParams } = useQueryParams({
		updateState: true,
	});

	const [paramId , setParamId] = useState("");

	useEffect(() => {
		const param = envelopeId || window.location.pathname.split("/").slice(-1)[0]
		if (param) {
			setParamId(param)
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[window.location.pathname, envelopeId])

	useEffect(() => {
		if (submit_esign) {
			WebComponentMemory.handleFinish = submit_esign;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const { brandColor, bodyFont } = organization.settings;
		const brandPrimaryColor = brandColor ? brandColor : '#3c65d6';
		const brandPrimaryFont = bodyFont?.family ? bodyFont?.family : 'Poppins';
		const styleTag = document.createElement('style');
		styleTag.id = 'themeStyle';
		styleTag.innerHTML = `
			:root body {
			--color-esign-primary-light: ${brandPrimaryColor};
			--color-input-border-focus-light: ${brandPrimaryColor};
			font-family: ${brandPrimaryFont}, sans-serif;
			}`;
		document.head.appendChild(styleTag);
	}, [organization]);

	useEffect(() => {
		if (env) {
			const {host} = ENV_HOST[env as IEnv];
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			setEnv(env as any);
			if (host) {
				WebComponentMemory.host = host
			}
			WebComponentMemory.isWebComponent = true
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [env]);
	const { getClient } = useClientUser();
	const { fetchLocation } = useLocation();
	const { getGeoInfo } = useGetLoationDetails();

	useEffect(() => {
		fetchLocation();
		getReadOnlyParams();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		// this needs to be removed when we will provide access to add web component for users
		const paramEnvelopeId = url?.replace('prepare/envelope/', '');
		getEnvelopeIdParams(paramEnvelopeId);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [url]);

	useEffect(() => {
		if (apiHostExists) {
			getClient();
		}
	}, [getClient, apiHostExists]);


	const { handleRealtimeDocsCallback } = useDocument()

	useEffect(() => {
		if (paramId) {
			handleRealtimeDocsCallback(paramId)
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[paramId])

	const initialiseFullStory = useCallback(() => {
		if (!currentRecipientId) return;
		const recipients = signDocState?.data?.recipients ?? [];
		const recipientDetail = recipients.find(
			({ _id }) => _id === currentRecipientId
		);
		if (recipientDetail) {
			const { fullName, email } = recipientDetail ?? {};
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			(window as any).FS?.setUserVars?.({
				displayName: fullName,
				email,
			});
		}
	}, [currentRecipientId, signDocState]);

	useEffect(() => {
		initialiseFullStory();
	}, [initialiseFullStory]);

	useEffect(() => {
		const { latitude = 0, longitude = 0 } = locationInfo ?? {};
		if (latitude && longitude && !googleLocation) {
			getGeoInfo({
				lat: latitude,
				lng: longitude,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [locationInfo]);

	const renderContent = useCallback(
		(envelopeId: string) => {
			if (showConfigDoc) {
				return <EditorsDashboard envelopeId={envelopeId} />;
			} else if (showSelfSignDoc) {
				return <SelfSign envelopeId={envelopeId} />;
			} else {
				return <PrepareDashboard from="envelope" envelopeId={envelopeId} />;
			}
		},
		[showConfigDoc, showSelfSignDoc]
	);

	if (url && env) {
		const prepare = url.includes('prepare');
		const signDoc = url.includes('sign-doc');
		setUiShowHide({ hideLogo: hide_logo, hideUser: hide_user });
		if (signDoc) {
			const [envelopeId, recipientId] = url
				.replace('sign-doc/', '')
				.split('?recipient=');
			setWebComponentData((prev) => ({
				...prev,
				recipientId: recipientId || '',
				envelopeId: envelopeId || '',
			}));
			return (
				<SigningDashboard envelopeId={envelopeId} recipientId={recipientId} />
			);
		}
		if (prepare) {
			const envelopeId = url.replace('prepare/envelope/', '');
			// setWebComponentEnvelopeId(envelopeId);
			getEnvelopeIdParams(envelopeId);
			return renderContent(envelopeId);
		}
	}
	return (
		<Routes>
			<Route path={`${SIGN_DOC}/:id`} element={<SigningDashboard />} />
			<Route path={`${SELF_SIGN}/:id`} element={<SelfSign />} />
			<Route path={`${CONFIG_DOC}`} element={<EditorsDashboard />} />
			<Route path={`${VIEW_DOC}`} element={<EditorsDashboard />} />
			<Route path={LINK_EXPIRED} element={<LinkExpired />} />
			<Route path={ERROR} element={<ServerError />} />
			<Route
				path={CONFIGSUCCESS}
				element={<SuccessScreen message={'Document configuration completed'} />}
			/>
			<Route
				path={SIGNINSUCCESS}
				element={
					<SuccessScreen
						message={'Document signing completed'}
						description="You will receive a copy once everyone has signed."
					/>
				}
			/>
			<Route
				path={VIEW_SUCCESS}
				element={
					<SuccessScreen
						message={'You are all done!'}
						description="You will receive a copy once everyone has signed."
					/>
				}
			/>
			<Route
				path={ENVELOPE_CONFIG_SUCCESS}
				element={<SuccessScreen message={'Invitation sent'} />}
			/>
			<Route path={REDIRECT_TO_SIMPLICI} element={<BackToSimplici />} />
			<Route
				path={`${PREPARE}/${ENVELOPE}/:id`}
				element={<PrepareDashboard from="envelope" />}
			/>
			<Route
				path={`${PREPARE}/${OVERLAY}/:id`}
				element={<PrepareDashboard from="overlay" />}
			/>
			<Route path={AGREMENT} element={<ESignAgrement/>}/>
			<Route path={'*'} element={<Navigate to={ERROR} />} />
		</Routes>
	);
};
