import { DragEvent, MutableRefObject, RefObject } from 'react';

import { IMetadata, IPosition, ISignature, ITab } from 'views/signing-dashboard';

export type IMenuTab =
	| 'custom'
	| 'standard'
	| 'compliance'
	| 'questionnaire'
	| 'kyb'
	| 'Common Fields'
	| 'questionnaire';

	export type IFieldNodeType =
	| "signature"
	| "date"
	| "initial"
	| "checkbox"
	| "email"
	| "name"
	| "hyperlink"
	| "text"
	| "title"
	| "radiogroup";
	
	export type IAllowedDragableNodesType = IFieldNodeType &
	(
		| 'rating'
		| 'dropdown'
		| 'tagbox'
		| 'boolean'
		| 'comment'
		| 'multipletext'
	);
	
export interface IPdfImage {
	readonly path: string;
	readonly _id: string;
	readonly name: string;
	readonly createdAt: Date;
	readonly updatedAt: Date;
	readonly height:  number;
	readonly width: number;
}

export interface IConfigDocument {
	name: string;
	createdAt: string | Date;
	url: string;
	readonly id?: string;
	pages: IPdfImage[];
	path?: string;
	_id: string;
}


export interface IFieldNode {
	type: string;
	label: string;
	key: string;
	placeholder?: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	default: any;
	textType?: number | string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	options?: { label: any; value: any }[];
	// eslint-disable-next-line no-use-before-define
	validationField?: ValidationField
}

export interface ValidationField {
    [key: string]: IFieldNode;
}

export type IMasterConfigState = {
	[key in IFieldNodeType]: IFieldNode[];
};

export type ICell =  {
	row: string,
	column: string
}

export interface IChoices {
    value: string;
    text: string;
}

export interface ITabMenu {
	id: string;
	label: string;
	key: string;
	icon: string;
	fieldType: IFieldNodeType;
	questionType?: string;
	value?: string;
	createdAt?: Date;
	updatedAt?: Date;
	source?: IMenuTab;
	name?: string;
	_id?: string;
	type?: string;
	isEditable?: boolean;
	isNew: boolean;
	cell? : ICell;
	recipientId?: string | null
	index?: number | null;
	choices?: IChoices[];
}

export interface ILeftSidebarData {
	id: string;
	label: string;
	key: string;
	icon: string;
	fieldType: IFieldNodeType;
}

export interface IMenuTabButtons {
	label: Capitalize<IMenuTab>;
	name: IMenuTab;
	className: string;
}

export interface ITabFields {
	heading: string;
	items: ITabMenu[];
}

export type IFields = {
	[key in string]: ITabFields;
};
export interface IConfigItems {
	id: string;
	fieldType: IFieldNodeType;
	index: number;
	isEditable?: boolean;
	recipient?: string;
	source?: string;
	validationType?: string;
}

export interface IDropedItems extends ILeftSidebarData {
	pageNumber: number;
	metadata: IMetadata;
	recipient: string;
	position: IPosition;
	source: string;
	fontSize: {
		label: string;
		value: string;
	}
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	value: any;
	documentId?: string;
	pageWidth?: number;
	containerWidth?: number;
	offsetWidth?: number;
	offsetHeight?: number;
	containerHeight?: number;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	questionType?: any;
	isEditable?: boolean;
	isNewField?: boolean;
	signature?: ISignature;
	__v?: number;
	cell: {
		row: string;
        column: string;
	}
	choices?: IChoices[];
	name?: string;
}
export type IRefs = {
	undoPointer: MutableRefObject<number>;
	changes: MutableRefObject<IDropedItems[][]>;
	isUndoRedo: MutableRefObject<boolean>;
	readOnly?: boolean;
};

export type IRefProps = {
	refs: IRefs;
	showZindex?: boolean;
};

export interface ICustomTab {
	_id: string;
	name: string;
	label: string;
	type: string;
	source: string;
	isEditable: boolean;
	icon: string;
	recipientId: string | null;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	index?: any;
	createdAt: string;
	updatedAt?: string;
}

export type DragOffsetType = {
	offsetX: number;
	offsetY: number;
};
export type IDragStartHandler = (
	event: DragEvent<HTMLDivElement>,
	item: IDropedItems,
	ref?: RefObject<HTMLDivElement>
) => void;

export enum DragTypes {
	DroppedItem = 'DroppedItem',
	SideBarItem = 'SideBarItem',
	Recipient = 'Recipients',
}

interface IRecipient {
	title: string;
	fullName: string | null;
	email: string | null;
	phone: string;
	signature: string | null;
	action: string;
	role: string;
	status: string;
	message: string | null;
	externalUser: boolean;
	completedAt: string | null;
	consent: boolean;
	_id: string;
}

interface IDocument {
	document: {
		_id: string;
		name: string;
		size: string;
		path: string;
		pages: IDocument[];
		pageIndex: number | null;
		deleted: boolean;
		md5Hash: string;
		createdAt: string;
		updatedAt: string;
	};
	tabs: ITab[]; // Replace 'any' with the actual data type
}

export type IConfigurationOption = 'kyc' | 'kyb' | 'questionnaire';

export type IConfiguration = {
	[key in IConfigurationOption]: boolean | undefined;
};

export interface ITemplate {
	_id: string;
	name: string;
	documents: IDocument[];
	customTabs: ICustomTab[]; // Replace 'any' with the actual data type
	status: string;
	kycFlow: boolean;
	configuration: IConfiguration;
	signOrder: boolean;
	type: string;
	recipients: Partial<IRecipient>[];
	createdAt: string;
	updatedAt: string;
	__v: number;
	templateId: string;
}


// interface for Tabs api response

export interface IOption {
    label: string;
    value: string;
}

export interface IField {
    type: string;
    label: string;
    key: string;
    placeholder?: string;
    default?: IOption | string | boolean | string[] | undefined; // Adjust type as per actual data
    textType?: IOption | string; // Additional field for 'input' type
    options?:  (IOption | string)[]; // Additional field for 'select' type
	// eslint-disable-next-line no-use-before-define
	validationField?: ITabValidationField;
}

export interface ITabValidationField {
    [key: string]: IField | string;
}

interface Tab {
    label: string;
    name: string;
    type: string;
    source: string;
    icon: string;
    checked?: boolean; // Additional field for 'checkbox' type
    isEditable?: boolean; // Additional field for 'text' type
    readOnly?: string; // Additional field for 'date' type
}

export interface ITabsConfiguration {
    text: IField[];
    name: IField[];
    email: IField[];
    checkbox: IField[];
    radiogroup: IField[];
    initial: IField[];
    date: IField[];
    signature: IField[];
    hyperlink: IField[];
    title: IField[];
}

interface Tabs {
    standard: Tab[];
    compliance: Tab[];
    kyb: Tab[];
    common: Tab[];
}

export interface IMenuTabState {
    configuration: ITabsConfiguration;
    tabs: Tabs;
}
