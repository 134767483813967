import env from "@beam-australia/react-env";

export const REACT_APP_API_HOST = env("ESIGN_API_HOST");
export const REACT_APP_HOST_URL = env("ESIGN_HOST_URL");
export const REACT_APP_CDN_URL = env("CDN_URL");
export const REACT_APP_ESIGN_FIREBASE_CONFIG = env("ESIGN_FIREBASE_CONFIG");
export const REACT_APP_FIREBASE_APIKEY = env("FIREBASE_APIKEY");
export const REACT_APP_FIREBASE_AUTHDOMAIN = env("FIREBASE_AUTHDOMAIN");
export const REACT_APP_FIREBASE_DATABASEURL = env("FIREBASE_DATABASEURL");
export const REACT_APP_FIREBASE_PROJECTID = env("FIREBASE_PROJECTID");
export const REACT_APP_FIREBASE_STORAGEBUCKET = env("FIREBASE_STORAGEBUCKET");
export const REACT_APP_FIREBASE_MESSAGINGSENDERID = env("FIREBASE_MESSAGINGSENDERID");
export const REACT_APP_FIREBASE_AAPID = env("FIREBASE_AAPID");
export const REACT_APP_LIQUIDITY_BUSINESSID = env('LIQUIDITY_BUSINESSID');
