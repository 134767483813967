export type IUrl =
	| 'template'
	| 'tabs'
	| 'envelope'
	| 'signature'
	| 'upload'
	| 'status'
	| 'config'
	| 'multi_sign'
	| 'documents'
	| 'page'
	| 'consent'
	| 'biometrics'
	| 'ip_address'
	| 'sessions'
	| 'signature_font'
	| 'document_url'
	| 'receipient_suggestion'
	| 'pdf_upload'
	| 'draft_envelope';


export const API_URL: { [key in Uppercase<IUrl>]: string } = {
	TEMPLATE: '/template',
	TABS: '/tab',
	ENVELOPE: '/envelopes',
	SIGNATURE: '/signatures',
	UPLOAD: '/upload',
	STATUS: '/template-status',
	CONFIG: '/config-doc',
	MULTI_SIGN: '/envelope-multisign',
	DOCUMENTS: '/documents',
	PAGE: '/pages',
	CONSENT: '/consent',
	BIOMETRICS: '/biometrics',
	SIGNATURE_FONT: '/signature-font',
	IP_ADDRESS: '/ip',
	SESSIONS: '/sessions',
	RECEIPIENT_SUGGESTION: '/receipient-suggestion',
	DOCUMENT_URL: '/pdf/fetch',
	PDF_UPLOAD: "/pdf/upload",
	DRAFT_ENVELOPE: "/draft-envelope"
};

export const GOOGLE_API = {
	IP: 'https://us-central1-glossy-fastness-305315.cloudfunctions.net/locate',
};
