import type { IReactSwitch } from './types';

import { FC, useMemo } from 'react';

import Switch from 'react-switch';
import { useRecoilValue } from 'recoil';
import { organizationDetailState } from 'states';

export const ReactSwitch: FC<IReactSwitch> = ({
	id,
	checked,
	handleChange,
	width,
	height,
	onColor,
	offColor,
	diameter,
	isDisabled = false,
}) => {
	const organization = useRecoilValue(organizationDetailState);

	const getColor = useMemo(() => {
		const { brandColor } = organization.settings;
		if (brandColor) {
			return brandColor;
		} else {
			return onColor ?? '#4574f5';
		}
	}, [onColor, organization]);

	return (
		<Switch
			id={id}
			checked={checked ?? false}
			disabled={isDisabled}
			checkedIcon={false}
			onColor={getColor}
			offColor={offColor ?? '#C9CDD6'}
			uncheckedIcon={false}
			height={height ?? 24}
			width={width ?? 48}
			handleDiameter={diameter ?? 16}
			onChange={handleChange}
		/>
	);
};
