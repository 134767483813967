import type { IDateCalender } from './type';

import React, {
	ChangeEvent,
	useCallback,
	useEffect,
	useRef,
	useState,
	KeyboardEvent,
	useMemo,
} from 'react';

import {
	Calendar,
	DayValue,
} from '@hassanmojab/react-modern-calendar-datepicker';
import InputMask from 'react-input-mask';
import { useOutsideClick } from 'hooks';

import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';
import './react-date-picker.scss';

const maxDate = new Date('2100-12-31');
const minDate = new Date('1900-01-01');

const maximumDate = {
	year: maxDate.getFullYear(),
	month: maxDate.getMonth() + 1,
	day: maxDate.getDate(),
};
const minimimDate = {
	year: minDate.getFullYear(),
	month: minDate.getMonth() + 1,
	day: minDate.getDate(),
};

/**
 * DateCalender component provides a date input field with a calendar dropdown.
 *
 * @param {IDateCalender} props - The props for the DateCalender component.
 * @returns {JSX.Element} The rendered DateCalender component.
 */
export const DateCalender: React.FC<IDateCalender> = ({
	value,
	onChange,
	isError = false,
	errorMessage,
	label,
	isRequired = false,
	id,
	direction,
	inputStyle = {},
	isDisabled = false,
	formate = 'MM/DD/YYYY',
	background = '#ffffff',
	isInputEditable=true,
}) => {
	const [showCalendar, setShowCalendar] = useState<boolean>(false);
	const [calenderFocused, setCalenderFocused] = useState<boolean>(false);
	const [inputValue, setInputValue] = useState<string>('');
	const wrapperRef = useRef<HTMLDivElement>(null);

	/**
	 * Formats a date object to a string in MM/DD/YYYY format.
	 *
	 * @param {Object} date - The date object to format.
	 * @param {number} date.year - The year of the date.
	 * @param {number} date.month - The month of the date.
	 * @param {number} date.day - The day of the date.
	 * @returns {string} The formatted date string.
	 */
	const formatDate = ({
		year,
		month,
		day,
	}: {
		day: number;
		month: number;
		year: number;
	}) => {
		const finalDay = day.toString().padStart(2, '0');
		const finalMonth = month.toString().padStart(2, '0');
		if(formate ==='dd/MM/yyyy'){
			return `${finalDay}/${finalMonth}/${year}`;
		} else if(formate === 'yyyy/MM/dd'){
			return `${year}/${finalDay}/${finalMonth}`;
		}else{
			return `${finalMonth}/${finalDay}/${year}`;
		}
	};

	useEffect(() => {
		if (value) {
			const newValue = formatDate(value);
			setInputValue(newValue);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value, formate]);

	/**
	 * Handles changes to the input field.
	 *
	 * @param {ChangeEvent<HTMLInputElement>} e - The change event.
	 */
	const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		if (value) {
			const [month, day, year] = value.split('/');
			let newDate;
			if (
				day &&
				month &&
				year &&
				Number(year) >= minimimDate.year &&
				Number(year) <= minimimDate.year &&
				!isNaN(Number(year)) &&
				!isNaN(Number(month)) &&
				!isNaN(Number(year))
			) {
				newDate = {
					day: +day,
					month: +month,
					year: +year,
				};
			}
			const formattedDate = {
				target: {
					value: newDate ?? null,
				},
			};
			setInputValue(value);
			onChange(formattedDate);
		}
	};

	/**
	 * Handles changes from the calendar component.
	 *
	 * @param {DayValue} day - The selected day value.
	 */
	const onCalenderChange = (day: DayValue) => {
		if (day) {
			const newValue = formatDate(day);
			setInputValue(newValue);
			const formattedDate = {
				target: {
					value: day,
				},
			};
			onChange(formattedDate);
		}
		setCalenderFocused(false);
		setShowCalendar(false);
	};

	const handleBlur = useCallback(() => {
		const [month, day] = inputValue.split('/');
		const isMonthOutOfRange = Number(month) > 12 || Number(month) < 1;
		const isDayOutOfRange = Number(day) > 31 || Number(day) < 1;
		if (isMonthOutOfRange || isDayOutOfRange) {
			const formattedDate = {
				target: {
					value: null,
				},
			};
			setInputValue('');
			onChange(formattedDate);
		}
	}, [inputValue, onChange]);

	const handleKeyPress = useCallback(
		(event: KeyboardEvent<HTMLInputElement>) => {
			const { key } = event;
			if (key) {
				setCalenderFocused(false);
				setShowCalendar(false);
			}
		},
		[]
	);

	useOutsideClick(wrapperRef, () => {
		setShowCalendar(false);
		setCalenderFocused(false);
	});

	const renderCalenderClass = useMemo(() => {
		return `date-calender-overlay date-calender-overlay--${direction}`;
	}, [direction]);

	return (
		<div ref={wrapperRef} style={{ width: '100%' }}>
			{label && (
				<label htmlFor={id} className="input__label">
					{label}
					{isRequired && <span className="input__isRequired"> *</span>}
				</label>
			)}
			<InputMask
				onKeyUp={handleKeyPress}
				onClick={() => {
					setShowCalendar((prev) => !prev);
				}}
				onFocus={() => {
					setCalenderFocused(true);
				}}
				mask={inputValue}
				onChange={handleInputChange}
				placeholder={formate.toUpperCase()}
				autoComplete="off"
				onBlur={handleBlur}
				value={inputValue}
				id={id}
				style={{
					...inputStyle,
					background,
				}}
				disabled={isDisabled}
				className={`esign-react-date-picker ${
					isError ? 'active-error-message' : ''
				}`}
				readOnly={!isInputEditable}
			/>

			{(showCalendar || calenderFocused) && (
				<Calendar
					value={value}
					onChange={onCalenderChange}
					shouldHighlightWeekends
					maximumDate={maximumDate}
					minimumDate={minimimDate}
					calendarClassName={renderCalenderClass}
				/>
			)}
			{isError && <span className="error-message">{errorMessage}</span>}
		</div>
	);
};
